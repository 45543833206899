import { type IApi } from "./services/worker";
import React from "react";

export const ApiContext = React.createContext<IApi | null>(null);

export const ApiProvider = ({
  value,
  children,
}: React.PropsWithChildren<{ value: IApi }>) => {
  return <ApiContext.Provider value={value}>{children}</ApiContext.Provider>;
};

export const useApi = () => {
  const api = React.useContext(ApiContext);
  if (!api) {
    throw new Error("Missing API provider");
  }
  return api;
};
